.events {
  margin-bottom: 2rem;
  border-top: 0.0625rem solid $blue;
  list-style: none;
}

.events__item {

}

.events__link {
  @extend %clear;
  display: block;
  padding: 1rem 1.25rem;
  border-bottom: 0.0625rem solid;
  color: $blue;
  font-family: $sans-serif;
  font-weight: 500;
  @include mq(48em) {
    display: flex;
  }

  &:hover {
    @include mq(32em) {
      border-color: $blue-dark;
      background: $blue-dark;
      color: $white;
    }
  }
}

.events__date {
  color: $gray-dark;
  font-weight: 400;
  @include mq(48em) {
    width: 15%;
    float: left;
  }

  .events__link:hover & {
    @include mq(32em) {
      color: $white;
    }
  }
}

.events__title {
  @include mq(48em) {
    width: 75%;
    float: left;
  }
}

.events__icon {
  display: none;
  align-self: center;
  width: 1.25rem;
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
  margin-left: auto;
  float: right;
  @include mq(48em) {
    display: block;
  }
}
