.tweet {
  @extend %small;
  margin-bottom: 2rem;
  font-family: $sans-serif;
  font-size: 1rem;

  a:not([class]) {
    background-image: none;

    &:hover {
      color: $blue-dark;
    }
  }
}

.tweet__user {
  font-weight: 500;
}

.tweet__handle,
.tweet__time {
  font-size: 0.75rem;
}

.tweet__text {
  margin-top: 1rem;
  margin-bottom: 1rem;

  :last-child {
    margin-bottom: 0;
  }
}

.tweet__meta ul {
    display: inline-block;
    width: auto !important;
    padding: 0;
    margin: 0;
}

.tweet__meta li {
    display: inline-block;
    margin: 0 10px 0 0;
    padding: 0;
    background-image: none;
}

.tweet__meta a{
  color: $gray-medium;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: $gray;
  }
}
.tweet__meta a:before {
    font-family: "FontAwesome", Arial, sans-serif;
    font-size: 1em;
    margin-right: 5px;
}
.tweet__meta .retweets:before {
    content: '\f079';
}

.tweet__meta .heart:before {
    content: '\f004';
}

.tweet__meta .reply:before {
    content: '\f112';
}

.tweet__full {
  .tweet__handle, .tweet__time {
      font-size: .75rem;
      line-height: 1.5rem;
      margin: 0 .5rem;
  }

  .tweet__time{
    float: right;
  }

  .tweet__handle, .tweet__user {
    float: left;
  }

  .tweet__text {
    clear: both;
    float: left;
    font-size: 1.25rem;
    font-weight: 100;
  }

}
