.embed {
  margin-bottom: 2rem;
}

.embed--alt {
  @extend %clear;
  @include mq(48em) {
    display: flex;
    align-items: flex-end;
    width: 100%;
  }
}

.embed__media {
  .embed--alt & {
    @include mq(48em) {
      width: calc(66.666% - 1rem);
      margin-left: auto;
      float: right;
    }
  }
}

.embed__caption {
  @extend %small;
  margin-top: 1rem;
  border-bottom: $divider-gray;

  .embed--alt & {
    padding-top: 1rem;
    border-top: 0.5rem solid $tan;
    border-bottom: 0;
    @include mq(48em) {
      order: -1;
      width: calc(33.333% - 1rem);
      margin-top: 0;
      float: left;
    }
  }
}

.embed__video {
  position: relative;
  padding-bottom: 56.25%;
  > * {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  iframe {
    border: 0;
  }
}
