@keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeout {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes cycle {
  0% {
    left: -100%; }
  33% {
    left: -200%; }
  66% {
    left: -300%; } }

@keyframes fadeup {
  0% {
    transform: translateY(100%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes zoomin {
  0% {
    transform: scale(0.75);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes zoomout {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0.75);
    opacity: 0; } }

.wrap::before, .accordion__toggle::before, .alert::before, .embed--alt::before, .events__link::before, .feature::before, .feed__detail::before, .grid::before, .header__form::before, .wrap::after, .accordion__toggle::after, .alert::after, .embed--alt::after, .events__link::after, .feature::after, .feed__detail::after, .grid::after, .header__form::after {
  display: table;
  width: 0;
  content: ''; }

.wrap::after, .accordion__toggle::after, .alert::after, .embed--alt::after, .events__link::after, .feature::after, .feed__detail::after, .grid::after, .header__form::after {
  clear: both; }

.hide, .accordion__content, .card__overlay, .dropdown__list, .subnav--nested {
  position: absolute;
  left: -999rem; }

.accordion__item--active .accordion__content, .subnav__item--active > .subnav--nested {
  position: static;
  left: auto; }

/*! sanitize.css v3.3.0 | CC0 1.0 Public Domain | github.com/10up/sanitize.css */
/*
 * Normalization
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted; }

audio:not([controls]) {
  display: none; }

b,
strong {
  font-weight: bolder; }

button {
  -webkit-appearance: button;
  overflow: visible; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText; }

button,
select {
  text-transform: none; }

details {
  display: block; }

hr {
  overflow: visible; }

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%; }

input {
  -webkit-border-radius: 0; }
  input[type="button"], input[type="reset"], input[type="submit"] {
    -webkit-appearance: button; }
  input[type="number"] {
    width: auto; }
  input[type="search"] {
    -webkit-appearance: textfield; }
    input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
      -webkit-appearance: none; }

main {
  display: block; }

pre {
  overflow: auto; }

progress {
  display: inline-block; }

summary {
  display: block; }

svg:not(:root) {
  overflow: hidden; }

template {
  display: none; }

textarea {
  overflow: auto; }

[hidden] {
  display: none; }

/*
 * Universal inheritance
 */
*,
::before,
::after {
  box-sizing: inherit; }

* {
  font-size: inherit;
  line-height: inherit; }

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit; }

/*
 * Opinionated defaults
 */
* {
  margin: 0;
  padding: 0; }

*,
::before,
::after {
  border-style: solid;
  border-width: 0; }

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  touch-action: manipulation; }

select {
  -moz-appearance: none;
  -webkit-appearance: none; }
  select::-ms-expand {
    display: none; }
  select::-ms-value {
    color: currentColor; }

svg {
  fill: currentColor; }

[aria-busy="true"] {
  cursor: progress; }

[aria-controls] {
  cursor: pointer; }

[aria-disabled] {
  cursor: default; }

[hidden][aria-hidden="false"] {
  clip: rect(0 0 0 0);
  display: inherit;
  position: absolute; }
  [hidden][aria-hidden="false"]:focus {
    clip: auto; }

/*
 * Configurable defaults
 */
* {
  background-repeat: no-repeat; }

:root {
  background-color: #ffffff;
  box-sizing: border-box;
  color: #2b2b2b;
  cursor: default;
  font: 66.66667% "Crimson Text", serif; }

a {
  text-decoration: none; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit; }

button,
[type="button"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="reset"],
[type="search"],
[type="submit"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  min-height: 1.5em; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace; }

nav ol,
nav ul {
  list-style: none; }

small {
  font-size: 75%; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

textarea {
  resize: vertical; }

::-moz-selection {
  background-color: #b3d4fc;
  color: #ffffff;
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  color: #ffffff;
  text-shadow: none; }

:root {
  word-wrap: break-word;
  overflow-x: hidden;
  font-size: 100%;
  line-height: 1.5; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

svg {
  fill: currentColor; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0; }

.svgstore {
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  pointer-events: none; }
  .svgstore svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.svgstore--angle-down {
  padding-bottom: 100%; }

.svgstore--angle-left {
  padding-bottom: 100%; }

.svgstore--angle-right {
  padding-bottom: 100%; }

.svgstore--angle-up {
  padding-bottom: 100%; }

.svgstore--arrow-long {
  padding-bottom: 30.09709%; }

.svgstore--arrow-mask {
  padding-bottom: 0.625%; }

.svgstore--bars {
  padding-bottom: 100%; }

.svgstore--facebook {
  padding-bottom: 100%; }

.svgstore--gallery {
  padding-bottom: 100%; }

.svgstore--image {
  padding-bottom: 100%; }

.svgstore--instagram {
  padding-bottom: 100%; }

.svgstore--linkedin {
  padding-bottom: 100%; }

.svgstore--logo-albert-center {
  padding-bottom: 62.40876%; }

.svgstore--logo-albert-left {
  padding-bottom: 25.42857%; }

.svgstore--logo-arts-sciences-center {
  padding-bottom: 53.28467%; }

.svgstore--logo-arts-sciences-left {
  padding-bottom: 23.91931%; }

.svgstore--logo-bab-center {
  padding-bottom: 55.10949%; }

.svgstore--logo-bab-left {
  padding-bottom: 25.42857%; }

.svgstore--logo-business-center {
  padding-bottom: 62.0438%; }

.svgstore--logo-business-left {
  padding-bottom: 25.42857%; }

.svgstore--logo-center {
  padding-bottom: 25.42088%; }

.svgstore--logo-dental-center {
  padding-bottom: 62.0438%; }

.svgstore--logo-dental-left {
  padding-bottom: 25.42857%; }

.svgstore--logo-doisy-center {
  padding-bottom: 55.10949%; }

.svgstore--logo-doisy-left {
  padding-bottom: 25.42857%; }

.svgstore--logo-education-center {
  padding-bottom: 55.10949%; }

.svgstore--logo-education-left {
  padding-bottom: 25.42857%; }

.svgstore--logo-healthoutcomes-center {
  padding-bottom: 62.40876%; }

.svgstore--logo-healthoutcomes-left {
  padding-bottom: 25.42857%; }

.svgstore--logo-law-center {
  padding-bottom: 53.28467%; }

.svgstore--logo-law-left {
  padding-bottom: 23.91931%; }

.svgstore--logo-left {
  padding-bottom: 42.44604%; }

.svgstore--logo-madrid-center {
  padding-bottom: 55.10949%; }

.svgstore--logo-madrid-footer {
  padding-bottom: 125.8427%; }

.svgstore--logo-madrid-left {
  padding-bottom: 25.42857%; }

.svgstore--logo-medicine-center {
  padding-bottom: 53.64964%; }

.svgstore--logo-medicine-left {
  padding-bottom: 23.91931%; }

.svgstore--logo-nursing-center {
  padding-bottom: 55.10949%; }

.svgstore--logo-nursing-left {
  padding-bottom: 25.42857%; }

.svgstore--logo-parks-center {
  padding-bottom: 61.67883%; }

.svgstore--logo-parks-left {
  padding-bottom: 25.42857%; }

.svgstore--logo-philosophy-center {
  padding-bottom: 62.40876%; }

.svgstore--logo-philosophy-left {
  padding-bottom: 25.42857%; }

.svgstore--logo-professional-studies-center {
  padding-bottom: 53.64964%; }

.svgstore--logo-professional-studies-left {
  padding-bottom: 23.91931%; }

.svgstore--logo-school {
  padding-bottom: 61.24031%; }

.svgstore--logo-slucare-center {
  padding-bottom: 55.10949%; }

.svgstore--logo-socialjustice-center {
  padding-bottom: 62.40876%; }

.svgstore--logo-socialjustice-left {
  padding-bottom: 25.42857%; }

.svgstore--logo-sustainability-center {
  padding-bottom: 55.10949%; }

.svgstore--logo-sustainability-left {
  padding-bottom: 25.42857%; }

.svgstore--logo {
  padding-bottom: 121.34831%; }

.svgstore--play {
  padding-bottom: 100%; }

.svgstore--search {
  padding-bottom: 100%; }

.svgstore--twitter {
  padding-bottom: 100%; }

.svgstore--x {
  padding-bottom: 100%; }

.svgstore--youtube-play {
  padding-bottom: 100%; }

body {
  font-size: 1.25rem; }

h1:not([class]),
h2:not([class]),
h3:not([class]),
h4:not([class]),
.accordion__toggle,
h5:not([class]),
h6:not([class]) {
  margin-bottom: 1rem;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500; }

h1:not([class]) {
  margin-bottom: 2rem;
  font-size: 2.6rem;
  line-height: 1.2; }
  @media (min-width: 48em) {
    h1:not([class]) {
      font-size: 3.25rem;
      line-height: 1.1; } }

h2:not([class]) {
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.3; }
  @media (min-width: 48em) {
    h2:not([class]) {
      font-size: 2.75rem;
      line-height: 1.2; } }

h3:not([class]) {
  font-size: 1.8rem;
  line-height: 1.4; }
  @media (min-width: 48em) {
    h3:not([class]) {
      font-size: 2.25rem;
      line-height: 1.3; } }

h4:not([class]), .accordion__toggle {
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.4; }
  @media (min-width: 48em) {
    h4:not([class]), .accordion__toggle {
      font-size: 2rem;
      line-height: 1.3; } }

h5:not([class]) {
  font-size: 1.5rem;
  line-height: 1.4; }

h6:not([class]) {
  font-size: 1.25rem; }

p:not([class]), .p--alt, .p--large,
ul:not([class]),
.ul--plain,
ol:not([class]),
blockquote:not([class]) {
  margin-bottom: 2rem; }

ul:not([class]), .ul--plain,
ol:not([class]) {
  padding-left: 2rem; }
  ul:not([class]) li, .ul--plain li, ol:not([class]) li {
    margin-bottom: 0.75rem; }

blockquote:not([class]) {
  position: relative;
  padding-left: 3rem;
  font-size: 2rem; }
  blockquote:not([class])::before {
    position: absolute;
    left: 0;
    color: #9a7851;
    font-size: 6.5rem;
    line-height: 1;
    content: open-quote; }
  blockquote:not([class]) p {
    margin-bottom: 1rem; }

cite:not([class]) {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: bold; }

a:not([class]) {
  background-image: linear-gradient(rgba(0, 61, 165, 0.4), rgba(0, 61, 165, 0.4));
  background-repeat: repeat-x;
  background-position: 0 88%;
  background-size: 100% 0.0625rem;
  color: #003da5; }
  a:hover:not([class]) {
    background-image: linear-gradient(currentColor, currentColor);
    background-repeat: repeat-x;
    background-position: 0 88%;
    background-size: 100% 0.0625rem; }

.embed__caption, .facebook, .feed__detail, .media, .stat, .tweet, .widget {
  font-size: 1.0625rem; }
  .embed__caption p, .facebook p, .feed__detail p, .media p, .stat p, .tweet p, .widget p {
    margin-bottom: 1rem; }

hr {
  margin-bottom: 2rem;
  border-bottom: 0.125rem solid #bcbcbc; }

.p--alt {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500; }

.p--large {
  font-size: 1.75rem; }
  @media (min-width: 48em) {
    .p--large {
      font-size: 2.5rem; } }

.ul--plain {
  padding-left: 0;
  list-style: none; }

body {
  margin-top: 4rem; }
  @media (min-width: 64em) {
    body {
      margin-top: 0; } }

.wrap {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem; }
  @media (min-width: 32em) {
    .wrap {
      padding-right: 2rem;
      padding-left: 2rem; } }
  @media (min-width: 72em) {
    .wrap {
      padding-right: 3rem;
      padding-left: 3rem; } }
  @media (min-width: 80em) {
    .wrap {
      padding-right: 4rem;
      padding-left: 4rem; } }

.wrap--wide {
  max-width: 100rem; }

@media (min-width: 64em) {
  .main {
    width: calc(66.666% - 2rem);
    float: right; } }

@media (min-width: 72em) {
  .main {
    width: calc(66.666% - 3rem); } }

@media (min-width: 80em) {
  .main {
    width: calc(66.666% - 4rem); } }

@media (min-width: 64em) {
  .main--left {
    float: left; } }

@media (min-width: 64em) {
  .main--center {
    margin-right: auto;
    margin-left: auto;
    float: none; } }

@media (min-width: 64em) {
  .sidebar {
    width: calc(33.333% - 2rem);
    float: left;
    clear: left; } }

@media (min-width: 72em) {
  .sidebar {
    width: calc(33.333% - 3rem); } }

@media (min-width: 80em) {
  .sidebar {
    width: calc(33.333% - 4rem); } }

@media (min-width: 64em) {
  .sidebar--right {
    float: right;
    clear: right; } }

.main + .sidebar {
  margin-top: 4rem; }
  @media (min-width: 64em) {
    .main + .sidebar {
      margin-top: 0; } }

.section {
  margin-bottom: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem; }
  .section + .section,
  .hero + .section {
    margin-top: -2rem; }

.section--large {
  margin-bottom: 4rem;
  padding-top: 4rem;
  padding-bottom: 4rem; }
  .hero + .section--large {
    margin-top: -4rem; }
  .banner + .section--large {
    margin-top: -2rem; }

.section--gray-light {
  background: #f0f0f0; }

.align-left,
.align-right {
  margin-bottom: 2rem; }
  @media (min-width: 48em) {
    .align-left,
    .align-right {
      max-width: calc(50% - 1rem); } }

@media (min-width: 48em) {
  .align-left {
    margin-right: 2rem;
    float: left; } }

@media (min-width: 48em) {
  .align-right {
    margin-left: 2rem;
    float: right; } }

.align-center {
  margin-right: auto;
  margin-left: auto;
  text-align: center; }

.zoomin {
  opacity: 0; }

.zoomin--active {
  animation: zoomin 640ms ease; }

.sticky :last-child {
  margin-bottom: 0; }

.spacing {
  margin-bottom: 2rem; }

html body .juicer-feed .referral {
  display: none; }

html body .juicer-feed .j-paginate {
  margin-bottom: 0; }

.accordion {
  margin-bottom: 2rem;
  border-bottom: 0.125rem solid #bcbcbc; }

.accordion__item {
  border-top: 0.125rem solid #bcbcbc; }

.accordion__toggle {
  display: block;
  display: flex;
  align-items: center;
  padding-top: 1rem;
  color: #2b2b2b; }
  .accordion__toggle:hover,
  .accordion__item--active .accordion__toggle {
    color: #003da5; }

.accordion__toggle__text {
  width: calc(100% - 3.25rem);
  float: left; }

.accordion__toggle__icon {
  width: 1.25rem;
  margin-left: auto;
  float: right;
  color: #003da5; }
  .accordion__item--active .accordion__toggle__icon {
    transform: rotate(180deg); }

.alert {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: #be2e27;
  color: #fff; }

.alert__text {
  position: relative;
  padding-right: 2.5rem;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500; }
  @media (min-width: 32em) {
    .alert__text {
      padding-right: 3.5rem; } }
  @media (min-width: 72em) {
    .alert__text {
      padding-right: 4.5rem; } }
  @media (min-width: 80em) {
    .alert__text {
      padding-right: 5.5rem; } }
  .alert__text :last-child {
    margin-bottom: 0; }

.alert__close {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1.5rem;
  transform: translateY(-50%);
  color: #fff; }

.banner {
  position: relative;
  margin-bottom: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: #003da5;
  background-position: center;
  background-size: cover;
  color: #fff;
  text-shadow: 0 0 0.5rem rgba(43, 43, 43, 0.4); }
  @media (min-width: 64em) {
    .banner {
      background-attachment: fixed; } }
  .banner .wrap {
    position: relative; }
  .section + .banner {
    margin-top: -2rem; }
  .section--large + .banner {
    margin-top: -4rem; }
  .banner::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, rgba(43, 43, 43, 0.4), transparent);
    content: ''; }

.banner--tan {
  background: #e3d7ad;
  color: #2b2b2b;
  text-shadow: none; }
  .banner--tan::before {
    display: none; }

.banner--arrow {
  padding-bottom: 2.75rem; }
  .banner--arrow::after {
    background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23fff%22%20width%3D%221920%22%20height%3D%2212%22%20viewBox%3D%220%200%201920%2012%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0%200h948l12%2012%2012-12h948v12H0%22%2F%3E%3C%2Fsvg%3E);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0.75rem;
    background-position: center;
    background-size: 120rem 0.75rem;
    content: ''; }

@media (min-width: 64em) {
  .banner__split {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem; } }

.banner__split :last-child {
  margin-bottom: 0; }

.banner__image {
  margin-bottom: 2rem; }
  @media (min-width: 64em) {
    .banner__image {
      width: calc(50% - 1rem);
      margin-bottom: 0;
      float: left; } }

.banner__content {
  text-align: center; }
  @media (min-width: 64em) {
    .banner__content {
      width: calc(50% - 1rem);
      margin-left: auto;
      float: right; } }

.banner__headline {
  margin-bottom: 2rem;
  padding-top: 2rem; }
  @media (min-width: 32em) {
    .banner__headline {
      padding-top: 4rem; } }
  @media (min-width: 64em) {
    .banner__headline {
      width: calc(66.666% - 1rem);
      margin-bottom: 0;
      padding-top: 8rem;
      padding-bottom: 8rem;
      float: left; } }

.banner__buttons {
  padding-bottom: 2rem; }
  @media (min-width: 32em) {
    .banner__buttons {
      padding-bottom: 4rem; } }
  @media (min-width: 64em) {
    .banner__buttons {
      width: calc(33.333% - 1rem);
      margin-left: auto;
      padding-top: 8rem;
      padding-bottom: 8rem;
      float: right; } }

.banner__center {
  margin-top: 2rem;
  margin-bottom: 2rem; }
  @media (min-width: 32em) {
    .banner__center {
      margin-top: 8rem;
      margin-bottom: 8rem; } }
  @media (min-width: 64em) {
    .banner__center {
      margin-top: 12rem;
      margin-bottom: 12rem; } }

.banner__bottom {
  margin-top: 4rem; }
  @media (min-width: 32em) {
    .banner__bottom {
      margin-top: 16rem; } }
  @media (min-width: 64em) {
    .banner__bottom {
      margin-top: 24rem; } }
  .banner__bottom :last-child {
    margin-bottom: 0; }

.banner__title {
  font-size: 2rem;
  text-align: center; }
  @media (min-width: 32em) {
    .banner__title {
      font-size: 3rem; } }
  @media (min-width: 64em) {
    .banner__title {
      font-size: 4rem; } }

.banner__narrow {
  max-width: 32rem; }

.breadcrumbs {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 0.875rem; }

.dubourg-animate {
  fill-opacity: 0;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration: 1;
  animation-name: FillIn;
  animation-duration: .5s;
  animation-delay: 2.5s; }

@keyframes FillIn {
  from {
    fill-opacity: 0; }
  to {
    fill-opacity: 1; } }

.buildings__wrap {
  text-align: center;
  color: #003da5;
  margin-top: 1rem; }
  .buildings__wrap p {
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: .1rem solid #000; }

.buttons-inline {
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.dropdown__toggle, .dropdown__link, .subnav__link,
.button {
  display: inline-block;
  padding: 0.625rem 1.25rem;
  background-color: #003da5;
  color: #fff;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  text-shadow: none; }
  .dropdown__toggle:hover, .dropdown__link:hover, .subnav__link:hover,
  .button:hover {
    background-color: #00286c; }
  .buttons-inline .dropdown__toggle, .buttons-inline .dropdown__link, .buttons-inline .subnav__link, .buttons-inline
  .button {
    margin-right: 0.5rem;
    margin-bottom: 1rem;
    margin-left: 0.5rem; }

.button--slide {
  position: relative;
  transform: translate3d(0, 0, 0);
  background-color: transparent;
  overflow: hidden;
  -webkit-font-smoothing: antialiased; }
  .button--slide::before, .button--slide::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ''; }
  .button--slide::before {
    background: #003da5;
    z-index: -2; }
  .button--slide::after {
    transform: translateX(-100%);
    transition: transform 320ms ease;
    background: #00286c;
    z-index: -1; }
  .button--slide:hover {
    background-color: transparent; }
    .button--slide:hover::after {
      transform: translateX(0); }

.button--white {
  padding: 0.5rem 1.125rem;
  border: 0.125rem solid #fff;
  background-color: #fff;
  color: #003da5; }
  .button--white:hover {
    border-color: #fff;
    background-color: #00286c;
    color: #fff; }

.subnav__link--nested {
  background: #f0f0f0;
  color: #003da5; }
  .subnav__link--nested:hover {
    background: #f0f0f0;
    color: #00286c; }

.dropdown__link, .subnav__link {
  padding-right: 1.75rem;
  transition: padding 320ms ease; }
  .dropdown__link:hover, .subnav__link:hover {
    padding-right: 1.25rem;
    padding-left: 1.75rem; }

.button--full {
  display: block;
  text-align: center; }

.button--arrow {
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23003da5%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%3E%3Ctitle%3EPrimary%20Arrow%3C%2Ftitle%3E%3Cpath%20d%3D%22M4%202.3L6.3%200%2016%2010%206.3%2020%204%2017.7l7.5-7.7L4%202.3z%22%2F%3E%3C%2Fsvg%3E);
  display: inline;
  padding: 0 1rem 0 0;
  background-color: transparent;
  background-position: right center;
  background-size: 0.75rem 0.75rem;
  color: #003da5; }
  .button--arrow:hover {
    background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%2300286c%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%3E%3Ctitle%3EPrimary%20Arrow%3C%2Ftitle%3E%3Cpath%20d%3D%22M4%202.3L6.3%200%2016%2010%206.3%2020%204%2017.7l7.5-7.7L4%202.3z%22%2F%3E%3C%2Fsvg%3E);
    background-color: transparent;
    color: #00286c; }

.button__icon {
  width: 1rem;
  margin-top: 0.25rem;
  margin-right: 0.5rem;
  float: left; }

.card {
  display: block;
  width: 100%;
  margin-bottom: 2rem;
  border-bottom: 0.25rem solid #9a7851;
  background: #fff;
  color: #2b2b2b; }
  @media (min-width: 32em) {
    .card:hover {
      background: #00286c;
      color: #fff; } }

.card__image {
  position: relative; }

.card__overlay {
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  color: #00286c;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500; }
  @media (min-width: 32em) {
    .card:hover .card__overlay {
      left: 0; } }

.card__more {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.75rem;
  text-align: center; }

.card__arrow {
  width: 9rem; }

.card__detail {
  padding: 1.5rem;
  padding-bottom: 3rem; }
  .card__detail :last-child {
    margin-bottom: 0; }

.card__title {
  color: #003da5;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1.75rem;
  font-weight: 500; }
  @media (min-width: 32em) {
    .card:hover .card__title {
      color: #fff; } }

.dropdown {
  position: relative;
  margin-bottom: 2rem; }

.dropdown__toggle {
  display: block;
  position: relative;
  padding-right: 4.1875rem; }
  .dropdown--active .dropdown__toggle {
    background: #00286c; }
  @media (min-width: 64em) {
    .dropdown--subnav .dropdown__toggle {
      display: none; } }

.dropdown__icon {
  position: absolute;
  top: 50%;
  right: 0;
  width: 2.9375rem;
  padding: 1rem;
  transform: translateY(-50%); }
  .dropdown--active .dropdown__icon {
    transform: translateY(-50%) rotate(180deg); }

.dropdown__list {
  width: 100%;
  list-style: none;
  z-index: 1; }
  .dropdown--active .dropdown__list {
    left: 0; }
  @media (min-width: 64em) {
    .dropdown--subnav .dropdown__list {
      position: static;
      left: auto; } }

.dropdown__item {
  border-top: 0.0625rem solid #fff; }

.dropdown__link {
  display: block;
  font-size: 1rem; }

.embed {
  margin-bottom: 2rem; }

@media (min-width: 48em) {
  .embed--alt {
    display: flex;
    align-items: flex-end;
    width: 100%; } }

@media (min-width: 48em) {
  .embed--alt .embed__media {
    width: calc(66.666% - 1rem);
    margin-left: auto;
    float: right; } }

.embed__caption {
  margin-top: 1rem;
  border-bottom: 0.125rem solid #bcbcbc; }
  .embed--alt .embed__caption {
    padding-top: 1rem;
    border-top: 0.5rem solid #9a7851;
    border-bottom: 0; }
    @media (min-width: 48em) {
      .embed--alt .embed__caption {
        order: -1;
        width: calc(33.333% - 1rem);
        margin-top: 0;
        float: left; } }

.embed__video {
  position: relative;
  padding-bottom: 56.25%; }
  .embed__video > * {
    position: absolute;
    width: 100%;
    height: 100%; }
  .embed__video iframe {
    border: 0; }

.events {
  margin-bottom: 2rem;
  border-top: 0.0625rem solid #003da5;
  list-style: none; }

.events__link {
  display: block;
  padding: 1rem 1.25rem;
  border-bottom: 0.0625rem solid;
  color: #003da5;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500; }
  @media (min-width: 48em) {
    .events__link {
      display: flex; } }
  @media (min-width: 32em) {
    .events__link:hover {
      border-color: #00286c;
      background: #00286c;
      color: #fff; } }

.events__date {
  color: #444;
  font-weight: 400; }
  @media (min-width: 48em) {
    .events__date {
      width: 15%;
      float: left; } }
  @media (min-width: 32em) {
    .events__link:hover .events__date {
      color: #fff; } }

@media (min-width: 48em) {
  .events__title {
    width: 75%;
    float: left; } }

.events__icon {
  display: none;
  align-self: center;
  width: 1.25rem;
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
  margin-left: auto;
  float: right; }
  @media (min-width: 48em) {
    .events__icon {
      display: block; } }

.facebook {
  margin-bottom: 2rem;
  font-family: "brandon-grotesque", sans-serif; }
  .facebook a:not([class]) {
    background-image: none; }
    .facebook a:not([class]):hover {
      color: #00286c; }

.facebook__user {
  font-weight: 500; }

.facebook__handle,
.facebook__time {
  font-size: 0.75rem; }

.facebook__text {
  margin-top: 1rem;
  margin-bottom: 1rem; }
  .facebook__text :last-child {
    margin-bottom: 0; }

.facebook__meta {
  margin: 1rem 0; }

.facebook__meta ul {
  display: inline-block;
  width: auto !important;
  padding: 0;
  margin: 0; }

.facebook__meta li {
  display: inline-block;
  margin: 0 10px 0 0;
  padding: 0;
  background-image: none; }

.facebook__meta a {
  transition: color 0.2s ease-in-out;
  font-size: .85rem;
  margin: 2rem 1rem 0 0;
  color: #003da5; }
  .facebook__meta a:hover {
    color: #667788; }

.facebook__meta a:before {
  font-family: "FontAwesome", Arial, sans-serif;
  margin-right: 5px;
  color: #bcbcbc;
  transition: color 0.2s ease-in-out; }
  .facebook__meta a:before:hover {
    color: #667788; }

.facebook__meta .like:before {
  content: '\f164'; }

.facebook__meta .comment:before {
  content: '\f075'; }

.facebook__meta .share:before {
  content: '\f064'; }

.facebook__full .facebook__handle, .facebook__full .facebook__time {
  font-size: .75rem;
  line-height: 1.5rem;
  margin: 0 .5rem; }

.facebook__full .facebook__time {
  float: right; }

.facebook__full .facebook__handle, .facebook__full .facebook__user {
  float: left; }

.facebook__full .facebook__text {
  font-size: 1.25rem;
  font-weight: 100;
  clear: both;
  float: left; }

@media (min-width: 64em) {
  .feature {
    display: flex; } }

.feature__media {
  background-position: center;
  background-size: cover; }
  @media (min-width: 64em) {
    .feature__media {
      width: 50%;
      float: left; } }
  .feature__media::before {
    display: block;
    padding-bottom: 66.666%;
    content: ''; }

@media (min-width: 64em) {
  .feature__content {
    display: flex;
    width: 50%;
    float: right;
    border-top: 0.125rem solid #bcbcbc; } }

.feature__content :last-child {
  margin-bottom: 0; }

.feature__detail {
  align-self: center;
  width: 100%;
  max-width: 50rem;
  padding: 2rem 1rem; }
  @media (min-width: 32em) {
    .feature__detail {
      padding: 2rem; } }
  @media (min-width: 72em) {
    .feature__detail {
      padding-right: 3rem; } }
  @media (min-width: 80em) {
    .feature__detail {
      padding-right: 4rem;
      padding-left: 3rem; } }

.feed {
  margin-bottom: 1rem;
  list-style: none; }

.feed__item {
  padding-top: 1rem;
  border-top: 0.125rem solid #bcbcbc; }
  .feed__item:first-child {
    padding-top: 0;
    border-top: 0; }

.feed__detail {
  margin-bottom: 1rem; }
  .feed .feed__detail {
    margin-bottom: 0; }

.feed__image,
.feed__cycle {
  margin-bottom: 1rem; }

@media (min-width: 32em) {
  .feed__image--left,
  .feed__image--right {
    width: calc(33.333% - 1rem); } }

@media (min-width: 32em) {
  .feed__image--left {
    margin-right: 2rem;
    float: left; } }

@media (min-width: 32em) {
  .feed__image--right {
    margin-left: 2rem;
    float: right; } }

.feed__cycle {
  position: relative;
  height: 0;
  padding-bottom: 66.666%;
  overflow: hidden; }

.feed__cycle__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 400%;
  height: 100%; }
  .feed__cycle__container img {
    height: 100%;
    width: 25%;
    float: left; }
  .feed__detail:hover .feed__cycle__container {
    animation: cycle 3s steps(1) infinite; }

.feed__title {
  margin-bottom: 0.5rem;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500; }

.feed__title--large {
  font-size: 2rem; }

.feed__date {
  font-size: 85%; }

.footer {
  margin-top: 4rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-top: 0.75rem solid #bcbcbc;
  background: #003da5;
  color: #fff;
  font-family: "brandon-grotesque", sans-serif;
  text-align: center; }
  @media (min-width: 64em) {
    .footer {
      padding-top: 4rem;
      padding-bottom: 4rem;
      text-align: left; } }
  .banner + .footer,
  .section--bg + .footer {
    margin-top: -2rem; }
  .section--bg.section--large + .footer {
    margin-top: -4rem; }

@media (min-width: 64em) {
  .footer__main,
  .footer__copyright {
    width: calc(66.666% - 2rem);
    float: right; } }

@media (min-width: 72em) {
  .footer__main,
  .footer__copyright {
    width: calc(66.666% - 3rem); } }

@media (min-width: 80em) {
  .footer__main,
  .footer__copyright {
    width: calc(66.666% - 4rem); } }

.footer__tagline {
  margin-bottom: 2rem;
  font-family: "Crimson Text", serif;
  font-size: 2.5rem; }
  @media (min-width: 64em) {
    .footer__tagline {
      margin-bottom: 1rem; } }

.footer__social {
  margin-bottom: 2rem;
  list-style: none; }
  @media (min-width: 64em) {
    .footer__social {
      margin-bottom: 0.75rem; } }

.footer__social__item {
  display: inline-block;
  margin-right: 0.5rem;
  margin-left: 0.5rem; }
  @media (min-width: 64em) {
    .footer__social__item {
      margin-right: 1rem;
      margin-left: 0; } }

.footer__social__link {
  display: block;
  width: 2rem;
  padding: 0.5rem;
  border-radius: 50%;
  background: #fff;
  color: #003da5; }
  .footer__social__link:hover {
    background: #00286c;
    color: #fff; }

.footer__nav {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  list-style: none; }
  @media (min-width: 64em) {
    .footer__nav {
      margin-bottom: 0.5rem; } }

.footer__nav--util {
  font-size: 1rem; }
  @media (min-width: 64em) {
    .footer__nav--util {
      margin-bottom: 0.75rem; } }

.footer__nav__item {
  margin-bottom: 1rem; }
  @media (min-width: 64em) {
    .footer__nav__item {
      display: inline-block;
      margin-right: 2rem;
      margin-bottom: 0; } }
  @media (min-width: 64em) {
    .footer__nav--util .footer__nav__item {
      margin-right: 1rem; } }

.footer__nav__link {
  color: #fff; }
  .footer__nav__link:hover {
    background-image: linear-gradient(currentColor, currentColor);
    background-repeat: repeat-x;
    background-position: 0 88%;
    background-size: 100% 0.0625rem; }

.footer__logo {
  display: block;
  width: 10rem;
  margin-right: auto;
  margin-bottom: 2rem;
  margin-left: auto;
  color: #fff; }
  @media (min-width: 64em) {
    .footer__logo {
      width: 33.333%;
      margin-top: 1.125rem;
      margin-bottom: 0;
      padding-right: calc(16.666% - 5rem);
      padding-left: calc(16.666% - 5rem);
      float: left; } }

.footer__copyright {
  font-size: 0.75rem;
  text-transform: uppercase; }

.form {
  padding: 2rem 0;
  margin-bottom: 2rem; }
  * + .form {
    margin-top: 2rem; }

.form__label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.25rem; }
  .form__label .required {
    color: #be2e27; }

.form__input {
  border: 0.12rem solid #003da5;
  width: 100%;
  margin-bottom: 2rem;
  height: 3.5rem; }
  .form__input:focus {
    border-color: #003da5;
    box-shadow: none; }

.form__input {
  padding: 0 1rem; }

.form__select select {
  padding: 0.5rem 10% 0.5rem 0.5rem;
  border: 0.12rem solid #003da5;
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23003da5%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%3E%3Ctitle%3EPrimary%20Arrow%3C%2Ftitle%3E%3Cpath%20d%3D%22M17.7%204L20%206.3%2010%2016%200%206.3%202.3%204l7.7%207.5L17.7%204z%22%2F%3E%3C%2Fsvg%3E);
  border-radius: 0;
  background-position: 90% 50%;
  background-size: 1rem; }

.form__textarea {
  border: 0.12rem solid #003da5;
  width: 100%;
  margin-bottom: 2rem;
  padding: 0.5rem; }
  .form__textarea:focus {
    border-color: #003da5;
    box-shadow: none; }

.control__group {
  margin-bottom: 1rem; }

.input__select__multiple {
  border: 0.12rem solid #003da5; }
  .input__select__multiple option {
    padding: 0.2rem; }

.input__checkbox {
  margin-right: 0.5rem; }
  .input__checkbox .checkbox {
    margin-right: 0.5rem; }

.form-actions button {
  cursor: pointer; }

.grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem; }

.grid--scroll {
  display: block;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch; }
  @media (min-width: 32em) {
    .grid--scroll {
      margin-right: -2rem;
      margin-left: -2rem; } }
  @media (min-width: 48em) {
    .grid--scroll {
      display: flex;
      margin-right: -1rem;
      margin-left: -1rem;
      white-space: normal;
      overflow: visible; } }

.grid__item {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  float: left; }

.grid__item--scroll {
  display: inline-block;
  width: 18rem;
  float: none;
  white-space: normal;
  vertical-align: top; }
  @media (min-width: 48em) {
    .grid__item--scroll {
      display: block;
      width: 100%;
      float: left; } }
  @media (min-width: 32em) {
    .grid__item--scroll:first-child {
      margin-left: 1rem; } }
  @media (min-width: 48em) {
    .grid__item--scroll:first-child {
      margin-left: 0; } }
  @media (min-width: 32em) {
    .grid__item--scroll:last-child {
      margin-right: 1rem; } }
  @media (min-width: 48em) {
    .grid__item--scroll:last-child {
      margin-right: 0; } }

.grid__item--flex {
  display: flex; }

@media (min-width: 48em) {
  .grid__item--2 {
    width: 50%; } }

@media (min-width: 48em) {
  .grid__item--3 {
    width: 50%; } }

@media (min-width: 64em) {
  .grid__item--3 {
    width: 33%; } }

@media (min-width: 48em) {
  .grid__item--4 {
    width: 50%; } }

@media (min-width: 64em) {
  .grid__item--4 {
    width: 25%; } }

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 4rem;
  z-index: 2; }
  @media (min-width: 64em) {
    .header {
      position: relative;
      height: auto;
      border-top: 0.25rem solid #9a7851;
      border-bottom: 4rem solid #003da5; } }

@media (min-width: 64em) {
  .header__wrap {
    position: relative;
    height: 13.75rem; } }

.header__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #003da5; }
  @media (min-width: 64em) {
    .header__bg {
      display: none; } }

.header__logo-center {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 11.75rem;
  transform: translate(-50%, -50%);
  color: #fff; }
  @media (min-width: 64em) {
    .header__logo-center {
      display: none; } }

.header__logo {
  display: none; }
  @media (min-width: 64em) {
    .header__logo {
      display: block;
      position: relative;
      height: 9.75rem;
      margin-top: 2rem;
      margin-right: auto;
      margin-bottom: 2rem;
      margin-left: auto;
      color: #003da5; } }
  .header__logo .svgstore {
    position: absolute;
    top: 0;
    left: 50%;
    height: inherit;
    padding-bottom: 0;
    transform: translateX(-50%); }

.header__toggle {
  position: absolute;
  top: 50%;
  width: 1.5rem;
  transform: translateY(-50%);
  color: #fff; }
  @media (min-width: 64em) {
    .header__toggle {
      display: none; } }

.header__toggle--menu {
  left: 1rem; }
  @media (min-width: 32em) {
    .header__toggle--menu {
      left: 2rem; } }

.header__toggle--search {
  right: 1rem;
  width: 2.25rem; }
  @media (min-width: 32em) {
    .header__toggle--search {
      right: 2rem; } }
  .header-search-active .header__toggle--search {
    width: 1.5rem; }

.header__toggle__icon {
  display: block; }

.header-menu-active .header__toggle__icon--menu {
  display: none; }

.header__toggle__icon--search {
  padding: 0.5rem;
  border: 0.125rem solid #fff;
  border-radius: 50%; }
  .header-search-active .header__toggle__icon--search {
    display: none; }

.header__toggle__icon--close {
  display: none; }

.header-menu-active .header__toggle__icon--close--menu {
  display: block; }

.header-search-active .header__toggle__icon--close--search {
  display: block; }

.header__menu,
.header__search {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: calc(100vh - 4rem);
  padding: 2rem 1rem;
  transform: translateY(-100%);
  transition: transform 320ms ease;
  background: #00286c;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: -1; }
  @media (min-width: 32em) {
    .header__menu,
    .header__search {
      padding: 2rem; } }
  @media (min-width: 64em) {
    .header__menu,
    .header__search {
      overflow: visible; } }

@media (min-width: 64em) {
  .header__menu {
    position: static;
    max-height: none;
    padding: 0;
    transform: none;
    transition: none;
    background: transparent; } }

.header-menu-active .header__menu {
  transform: translateY(0); }
  @media (min-width: 64em) {
    .header-menu-active .header__menu {
      transform: none; } }

@media (min-width: 64em) {
  .header-fixed .header__menu {
    position: fixed;
    top: 0;
    height: 4rem;
    background: #003da5;
    overflow: hidden;
    z-index: auto; } }

@media (min-width: 64em) {
  .header-fixed .header__menu__wrap {
    position: relative;
    max-width: 80rem;
    margin-right: auto;
    margin-left: auto;
    padding-right: 2rem;
    padding-left: 2rem;
    animation: fadein 320ms ease; } }

@media (min-width: 72em) {
  .header-fixed .header__menu__wrap {
    padding-right: 3rem;
    padding-left: 3rem; } }

@media (min-width: 80em) {
  .header-fixed .header__menu__wrap {
    padding-right: 4rem;
    padding-left: 4rem; } }

.header__logo-left {
  display: none; }
  @media (min-width: 64em) {
    .header-fixed .header__logo-left {
      display: block;
      width: 7.5rem;
      margin-top: 0.3875rem;
      float: left;
      color: #fff; } }

@media (min-width: 64em) {
  .header-fixed .header__menu__group {
    margin-top: 0.84375rem;
    float: right; } }

.header__nav {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 0.125rem solid rgba(255, 255, 255, 0.6);
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  list-style: none; }
  @media (min-width: 64em) {
    .header__nav {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0; } }

.header__nav--util {
  text-transform: uppercase;
  overflow: hidden; }
  @media (min-width: 64em) {
    .header__nav--util {
      position: absolute;
      top: -0.25rem;
      left: 2rem;
      border-right: 0.0625rem solid #fff;
      border-left: 0.0625rem solid #fff;
      font-size: 0.875rem; } }
  @media (min-width: 72em) {
    .header__nav--util {
      left: 3rem; } }
  @media (min-width: 80em) {
    .header__nav--util {
      left: 4rem; } }
  @media (min-width: 64em) {
    .header-fixed .header__nav--util {
      position: static;
      float: left; } }

.header__nav--primary {
  font-size: 2rem; }
  @media (min-width: 64em) {
    .header__nav--primary {
      display: flex;
      position: absolute;
      top: 100%;
      left: 50%;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      max-width: 80rem;
      height: 4rem;
      padding-right: 2rem;
      padding-left: 2rem;
      transform: translateX(-50%);
      font-size: 1.25rem;
      text-align: center; } }
  @media (min-width: 72em) {
    .header__nav--primary {
      padding-right: 3rem;
      padding-left: 3rem; } }
  @media (min-width: 80em) {
    .header__nav--primary {
      padding-right: 4rem;
      padding-left: 4rem; } }
  @media (min-width: 64em) {
    .header-fixed .header__nav--primary {
      display: none; } }

.header__nav--action {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
  overflow: hidden; }
  @media (min-width: 64em) {
    .header__nav--action {
      position: absolute;
      top: -0.25rem;
      right: 2rem;
      font-size: 0.875rem; } }
  @media (min-width: 72em) {
    .header__nav--action {
      right: 3rem; } }
  @media (min-width: 80em) {
    .header__nav--action {
      right: 4rem; } }
  @media (min-width: 64em) {
    .header-fixed .header__nav--action {
      position: static;
      float: left; } }

@media (min-width: 64em) {
  .header-fixed .header__nav--util, .header-fixed
  .header__nav--actiong {
    border: 0; } }

.header__nav__item {
  margin-top: 2rem; }
  @media (min-width: 64em) {
    .header__nav__item {
      margin-top: 0; } }
  .header__nav__item:first-child {
    margin-top: 0; }
  @media (min-width: 64em) {
    .header__nav--util .header__nav__item {
      float: left;
      border-right: 0.0625rem solid #fff;
      border-left: 0.0625rem solid #fff; } }
  @media (min-width: 64em) {
    .header__nav--primary .header__nav__item {
      display: inline-block;
      position: relative;
      top: 50%;
      transform: translateY(-50%); }
      .header__nav--primary .header__nav__item + .header__nav__item {
        margin-left: 2rem; } }
  @media (min-width: 64em) {
    .header__nav--action .header__nav__item {
      margin-left: 2rem;
      float: left; } }
  @media (min-width: 64em) {
    .header-fixed .header__nav--util .header__nav__item, .header-fixed
    .header__nav--action .header__nav__item {
      margin-left: 0.5rem;
      border: 0; } }

.header__nav__item--hidden {
  display: none; }
  @media (min-width: 64em) {
    .header__nav__item--hidden {
      display: block; } }

.header__nav__link {
  color: #fff; }
  @media (min-width: 64em) {
    .header__nav--util .header__nav__link {
      display: block;
      padding-top: 0.25rem;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      border-top: 0.5rem solid;
      color: #003da5; }
      .header__nav--util .header__nav__link:hover {
        color: #00286c; } }
  @media (min-width: 64em) {
    .header__nav--primary .header__nav__link {
      display: block;
      position: relative;
      background: #003da5;
      overflow: hidden;
      -webkit-font-smoothing: antialiased; }
      .header__nav--primary .header__nav__link::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0.125rem;
        transform: translateX(-101%);
        transition: transform 320ms ease;
        background: #fff;
        content: ''; }
      .header__nav--primary .header__nav__link:hover::before {
        transform: translateX(0); } }
  @media (min-width: 64em) {
    .header__nav--primary .header__nav__item--active .header__nav__link::before {
      transform: translateX(0); } }
  @media (min-width: 64em) {
    .header__nav--action .header__nav__link {
      display: block;
      padding-top: 0.75rem;
      color: #003da5; }
      .header__nav--action .header__nav__link:hover {
        color: #00286c; } }
  @media (min-width: 64em) {
    .header-fixed .header__nav--util .header__nav__link, .header-fixed
    .header__nav--action .header__nav__link {
      padding: 0.5rem 0.75rem;
      border: 0;
      color: #fff; }
      .header-fixed .header__nav--util .header__nav__link:hover, .header-fixed
      .header__nav--action .header__nav__link:hover {
        background: #00286c; } }
  .header-fixed .header__nav__item--hidden .header__nav__link {
    margin-left: 0.75rem;
    padding: 0; }

.header__nav__icon {
  width: 0.75rem;
  margin-left: 0.25rem;
  padding: 0.25rem 0;
  float: right; }
  .header-fixed .header__nav__icon {
    width: 2.25rem;
    margin-left: 0;
    padding: 0.5rem;
    border: 0.125rem solid #fff;
    border-radius: 50%; }

.header-fixed .header__nav__text {
  display: none; }

.header__search {
  padding-right: 0;
  padding-left: 0; }
  @media (min-width: 64em) {
    .header__search {
      position: fixed;
      top: 0;
      z-index: auto; } }
  .header-search-active .header__search {
    transform: translateY(0); }

@media (min-width: 64em) {
  .header__search__wrap {
    position: relative;
    padding-right: 5.5rem;
    padding-left: 5.5rem; } }

@media (min-width: 72em) {
  .header__search__wrap {
    padding-right: 7.5rem;
    padding-left: 7.5rem; } }

@media (min-width: 80em) {
  .header__search__wrap {
    padding-right: 9.5rem;
    padding-left: 9.5rem; } }

.header__search__close {
  display: none; }
  @media (min-width: 64em) {
    .header__search__close {
      display: block;
      position: absolute;
      top: 50%;
      right: 2rem;
      width: 1.5rem;
      transform: translateY(-50%);
      color: #fff; } }
  @media (min-width: 72em) {
    .header__search__close {
      right: 3rem; } }
  @media (min-width: 80em) {
    .header__search__close {
      right: 4rem; } }

.header__form {
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.0625rem solid #fff;
  color: #fff;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1.5rem; }
  @media (min-width: 64em) {
    .header__form {
      width: calc(50% - 2rem);
      margin-bottom: 0;
      float: left; } }
  @media (min-width: 72em) {
    .header__form {
      width: calc(50% - 3rem); } }
  @media (min-width: 80em) {
    .header__form {
      width: calc(50% - 4rem); } }
  .header__form:last-child {
    margin-bottom: 0; }
    @media (min-width: 64em) {
      .header__form:last-child {
        float: right; } }

.header__form__input {
  width: calc(100% - 2.25rem);
  float: left; }
  .header__form__input::placeholder {
    color: #fff; }

.header__form__button {
  width: 2.25rem;
  padding: 0.5rem;
  float: right;
  border: 0.125rem solid #fff;
  border-radius: 50%;
  color: #fff; }

.heading {
  margin-bottom: 0.5rem;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.125rem;
  text-transform: uppercase; }

.heading--border {
  padding-top: 0.5rem;
  border-top: 0.125rem solid #bcbcbc; }

.heading--alt {
  position: relative;
  margin-bottom: 2rem;
  padding-bottom: 1.25rem;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: normal;
  text-align: center;
  text-transform: none; }
  .heading--alt::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 8rem;
    transform: translateX(-50%);
    border-bottom: 0.25rem solid #003da5;
    content: ''; }

.heading--alt--left {
  text-align: left; }
  .heading--alt--left::before {
    left: 0;
    transform: none; }

.heading--alt--overlay {
  margin-bottom: 1rem; }
  .heading--alt--overlay::before {
    border-color: #fff;
    box-shadow: 0 0 0.5rem rgba(43, 43, 43, 0.4); }

.heading__icon {
  display: inline-block;
  width: 1.25rem;
  color: #bcbcbc;
  vertical-align: text-bottom; }

.hero {
  position: relative;
  margin-bottom: 4rem;
  background-color: #003da5;
  background-position: top;
  background-size: cover;
  text-shadow: 0 0 0.5rem rgba(43, 43, 43, 0.4);
  overflow: hidden; }
  .hero::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(43, 43, 43, 0.4), transparent);
    content: ''; }

.hero__wrap {
  position: relative;
  padding-bottom: 125%; }
  @media (min-width: 32em) {
    .hero__wrap {
      padding-bottom: 100%; } }
  @media (min-width: 48em) {
    .hero__wrap {
      padding-bottom: 75%; } }
  @media (min-width: 64em) {
    .hero__wrap {
      padding-bottom: 70%; } }

@media (min-width: 64em) {
  .hero__wrap--large {
    padding-bottom: 70%; } }

.hero__headline {
  position: absolute;
  top: 2rem;
  left: 0;
  width: 100%;
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
  animation: fadeup 2560ms ease;
  z-index: 1; }
  @media (min-width: 32em) {
    .hero__headline {
      font-size: 2rem; } }
  @media (min-width: 48em) {
    .hero__headline {
      top: 3rem;
      font-size: 2.5rem; } }
  @media (min-width: 64em) {
    .hero__headline {
      font-size: 3rem; } }
  @media (min-width: 80em) {
    .hero__headline {
      font-size: 3.5rem; } }

.hero__title {
  position: absolute;
  bottom: 3rem;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  color: #fff;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 2rem;
  font-weight: 500;
  text-align: center; }
  @media (min-width: 32em) {
    .hero__title {
      font-size: 2.5rem; } }
  @media (min-width: 64em) {
    .hero__title {
      font-size: 3rem; } }
  .hero__title::before {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 8rem;
    height: 0.25rem;
    transform: translateX(-50%);
    background: #fff;
    box-shadow: 0 0 0.5rem rgba(43, 43, 43, 0.4);
    content: ''; }

.hero__video {
  position: relative;
  background-size: cover;
  background-position: 50%; }

.hero__video__media {
  position: absolute;
  animation: fade-in 2560ms ease;
  min-width: 100%;
  min-height: auto; }
  @media (min-width: 48em) {
    .hero__video__media {
      min-height: 51rem; } }

.media {
  margin-bottom: 2rem;
  font-family: "brandon-grotesque", sans-serif; }

.media__image {
  display: block;
  position: relative;
  margin-bottom: 1rem; }

.media__type {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.5rem;
  background: rgba(43, 43, 43, 0.4); }

.media__icon {
  width: 1.5rem;
  margin-right: 0.25rem;
  padding: 0.25rem;
  float: right;
  color: #fff; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.modal__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75); }

.modal__container {
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

.modal__status {
  text-align: center; }

.modal__item {
  display: block;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto; }

.modal__video {
  position: relative;
  padding-bottom: 56.25%;
  background: #000; }

.modal__embed {
  position: absolute;
  width: 100%;
  height: 100%; }

.modal__nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.modal__nav--previous {
  left: 0; }

.modal__nav--next {
  right: 0; }

.modal__close {
  position: absolute;
  top: 0;
  right: 0; }

.modal__overlay,
.modal__status,
.modal__controls,
.modal__close {
  opacity: 0;
  transition: opacity 300ms ease; }

.modal--active .modal__overlay,
.modal--active .modal__controls,
.modal--active .modal__close,
.modal--loading .modal__status,
.modal--loaded .modal__status {
  opacity: 1; }

.modal__item {
  opacity: 0;
  transform: scale(0.75);
  transition: opacity 300ms ease, transform 300ms ease; }

.modal--loaded .modal__item {
  opacity: 1;
  transform: scale(1); }

.modal__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(43, 43, 43, 0.9);
  animation: fadein 320ms ease; }
  .modal--remove .modal__overlay {
    opacity: 0;
    animation: fadeout 320ms ease; }

.modal__container {
  position: relative;
  width: 100%;
  max-width: 80rem;
  margin: auto;
  padding: 1rem; }
  @media (min-width: 32em) {
    .modal__container {
      padding: 2rem; } }
  @media (min-width: 72em) {
    .modal__container {
      padding: 3rem; } }
  @media (min-width: 80em) {
    .modal__container {
      padding: 4rem; } }

button.modal__nav {
  color: white; }

button.modal__nav:hover {
  cursor: pointer; }

button.modal__nav.modal__nav--previous:before {
  content: '\f053';
  font-family: FontAwesome,Arial,sans-serif;
  color: white;
  font-size: 2rem;
  margin: 0 1rem; }

button.modal__nav.modal__nav--next:after {
  content: '\f054';
  font-family: FontAwesome,Arial,sans-serif;
  color: white;
  font-size: 2rem;
  margin: 0 1rem; }

.stat {
  margin-bottom: 2rem;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1rem;
  font-weight: 500; }

.stat__number {
  color: #9a7851;
  font-family: "Crimson Text", serif;
  font-size: 4rem; }

.subnav {
  width: 100%;
  margin-bottom: 2rem;
  border-top: 0.25rem solid #9a7851;
  list-style: none; }

.subnav--nested {
  margin-bottom: 0;
  border-top: 0; }

.subnav__item {
  display: flex;
  flex-wrap: wrap;
  border-top: 0.0625rem solid #fff; }
  .subnav__item:first-child {
    border-top: 0; }
    .subnav--nested .subnav__item:first-child {
      border-top: 0.0625rem solid #fff; }

.subnav__toggle {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  width: 2.9375rem;
  padding: 1rem;
  float: right;
  background: #003da5;
  color: #fff; }
  .subnav__toggle:hover {
    background: #00286c; }
  .subnav__item--active > .subnav__toggle {
    transform: rotate(180deg);
    background: #00286c; }

.subnav__link {
  display: block;
  order: -1;
  width: 100%; }
  .subnav__toggle + .subnav__link {
    width: calc(100% - 2.9375rem); }
  .subnav__item--active > .subnav__link {
    background: #00286c; }

.subnav__link--nested {
  font-size: 1rem; }

.table {
  margin-bottom: 2rem;
  font-size: 1.125rem; }
  .table table {
    width: calc(100% + 0.25rem);
    margin-right: -0.125rem;
    margin-left: -0.125rem;
    border-collapse: separate;
    border-spacing: 0.125rem 0;
    table-layout: fixed; }
  .table tr:nth-child(odd) td {
    background: #f0f0f0; }
  .table th,
  .table td {
    padding: 0.625rem 1.25rem;
    text-align: left;
    vertical-align: top; }
  .table th {
    background: #003da5;
    color: #fff;
    font-size: 1.25rem;
    font-weight: normal; }

.table__shadow {
  position: relative; }
  .table__shadow::before {
    position: absolute;
    top: 0;
    right: 0;
    width: 0.375rem;
    height: 100%;
    background-image: linear-gradient(to right, transparent, rgba(43, 43, 43, 0.4));
    content: '';
    pointer-events: none; }

.table__scroll {
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  .table__scroll table {
    min-width: 64rem; }

.tweet {
  margin-bottom: 2rem;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1rem; }
  .tweet a:not([class]) {
    background-image: none; }
    .tweet a:not([class]):hover {
      color: #00286c; }

.tweet__user {
  font-weight: 500; }

.tweet__handle,
.tweet__time {
  font-size: 0.75rem; }

.tweet__text {
  margin-top: 1rem;
  margin-bottom: 1rem; }
  .tweet__text :last-child {
    margin-bottom: 0; }

.tweet__meta ul {
  display: inline-block;
  width: auto !important;
  padding: 0;
  margin: 0; }

.tweet__meta li {
  display: inline-block;
  margin: 0 10px 0 0;
  padding: 0;
  background-image: none; }

.tweet__meta a {
  color: #667788;
  transition: color 0.2s ease-in-out; }
  .tweet__meta a:hover {
    color: #bcbcbc; }

.tweet__meta a:before {
  font-family: "FontAwesome", Arial, sans-serif;
  font-size: 1em;
  margin-right: 5px; }

.tweet__meta .retweets:before {
  content: '\f079'; }

.tweet__meta .heart:before {
  content: '\f004'; }

.tweet__meta .reply:before {
  content: '\f112'; }

.tweet__full .tweet__handle, .tweet__full .tweet__time {
  font-size: .75rem;
  line-height: 1.5rem;
  margin: 0 .5rem; }

.tweet__full .tweet__time {
  float: right; }

.tweet__full .tweet__handle, .tweet__full .tweet__user {
  float: left; }

.tweet__full .tweet__text {
  clear: both;
  float: left;
  font-size: 1.25rem;
  font-weight: 100; }

.widget {
  margin-bottom: 2rem; }
