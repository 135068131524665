.hero {
  position: relative;
  margin-bottom: 4rem;
  background-color: $blue;
  background-position: top;
  background-size: cover;
  text-shadow: $shadow;
  overflow: hidden;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, $black-opacity, transparent);
    content: '';
  }
}


.hero__wrap {
  position: relative;
  padding-bottom: 125%;
  @include mq(32em) {
    padding-bottom: 100%;
  }
  @include mq(48em) {
    padding-bottom: 75%;
  }
  @include mq(64em) {
    padding-bottom: 70%;
  }
}

.hero__wrap--large {
  @include mq(64em) {
    padding-bottom: 70%;
  }
}

.hero__headline {
  position: absolute;
  top: 2rem;
  left: 0;
  width: 100%;
  color: $white;
  font-size: 1.5rem;
  text-align: center;
  animation: fadeup $transition-speed * 8 $transition-easing;
  z-index: 1;
  @include mq(32em) {
    font-size: 2rem;
  }
  @include mq(48em) {
    top: 3rem;
    font-size: 2.5rem;
  }
  @include mq(64em) {
    font-size: 3rem;
  }
  @include mq(80em) {
    font-size: 3.5rem;
  }
}

.hero__title {
  position: absolute;
  bottom: 3rem;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  color: $white;
  font-family: $sans-serif;
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  @include mq(32em) {
    font-size: 2.5rem;
  }
  @include mq(64em) {
    font-size: 3rem;
  }

  &::before {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 8rem;
    height: 0.25rem;
    transform: translateX(-50%);
    background: $white;
    box-shadow: $shadow;
    content: '';
  }
}

.hero__video {
  position: relative;
  background-size: cover;
  background-position: 50%;
}

.hero__video__media {
  position: absolute;
  animation: fade-in $transition-speed * 8 $transition-easing;
  min-width: 100%;
  min-height: auto;
  @include mq(48em) {
    min-height: 51rem;
  }
}
