body {
  margin-top: 4rem;
  @include mq(64em) {
    margin-top: 0;
  }
}

.wrap {
  @extend %clear;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  @include mq(32em) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  @include mq(72em) {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  @include mq(80em) {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}

.wrap--wide {
  max-width: 100rem;
}

.main {
  @include mq(64em) {
    width: calc(66.666% - 2rem);
    float: right;
  }
  @include mq(72em) {
    width: calc(66.666% - 3rem);
  }
  @include mq(80em) {
    width: calc(66.666% - 4rem);
  }
}

.main--left {
  @include mq(64em) {
    float: left;
  }
}

.main--center {
  @include mq(64em) {
    margin-right: auto;
    margin-left: auto;
    float: none;
  }
}

.sidebar {
  @include mq(64em) {
    width: calc(33.333% - 2rem);
    float: left;
    clear: left;
  }
  @include mq(72em) {
    width: calc(33.333% - 3rem);
  }
  @include mq(80em) {
    width: calc(33.333% - 4rem);
  }
}

.sidebar--right {
  @include mq(64em) {
    float: right;
    clear: right;
  }
}

.main + .sidebar {
  margin-top: 4rem;
  @include mq(64em) {
    margin-top: 0;
  }
}

.section {
  margin-bottom: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  & + &,
  .hero + & {
    margin-top: -2rem;
  }
}

.section--large {
  margin-bottom: 4rem;
  padding-top: 4rem;
  padding-bottom: 4rem;

  .hero + & {
    margin-top: -4rem;
  }

  .banner + & {
    margin-top: -2rem;
  }
}

.section--gray-light {
  background: $gray-light;
}
