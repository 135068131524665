.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 4rem;
  z-index: 2;
  @include mq(64em) {
    position: relative;
    height: auto;
    border-top: 0.25rem solid $tan;
    border-bottom: 4rem solid $blue;
  }
}

.header__wrap {
  @include mq(64em) {
    position: relative;
    height: 13.75rem;
  }
}

.header__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $blue;
  @include mq(64em) {
    display: none;
  }
}

.header__logo-center {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 11.75rem;
  transform: translate(-50%, -50%);
  color: $white;
  @include mq(64em) {
    display: none;
  }
}

.header__logo {
  display: none;
  @include mq(64em) {
    display: block;
    position: relative;
    height: 9.75rem;
    margin-top: 2rem;
    margin-right: auto;
    margin-bottom: 2rem;
    margin-left: auto;
    color: $blue;
  }

  .svgstore {
    position: absolute;
    top: 0;
    left: 50%;
    height: inherit;
    padding-bottom: 0;
    transform: translateX(-50%);
  }
}

.header__toggle {
  position: absolute;
  top: 50%;
  width: 1.5rem;
  transform: translateY(-50%);
  color: $white;
  @include mq(64em) {
    display: none;
  }
}

.header__toggle--menu {
  left: 1rem;
  @include mq(32em) {
    left: 2rem;
  }
}

.header__toggle--search {
  right: 1rem;
  width: 2.25rem;
  @include mq(32em) {
    right: 2rem;
  }

  .header-search-active & {
    width: 1.5rem;
  }
}

.header__toggle__icon {
  display: block;
}

.header__toggle__icon--menu {
  .header-menu-active & {
    display: none;
  }
}

.header__toggle__icon--search {
  padding: 0.5rem;
  border: 0.125rem solid $white;
  border-radius: 50%;

  .header-search-active & {
    display: none;
  }
}

.header__toggle__icon--close {
  display: none;
}

.header__toggle__icon--close--menu {
  .header-menu-active & {
    display: block;
  }
}

.header__toggle__icon--close--search {
  .header-search-active & {
    display: block;
  }
}

.header__menu,
.header__search {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: calc(100vh - 4rem);
  padding: 2rem 1rem;
  transform: translateY(-100%);
  transition: transform $transition;
  background: $blue-dark;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: -1;
  @include mq(32em) {
    padding: 2rem;
  }
  @include mq(64em) {
    overflow: visible;
  }
}

.header__menu {
  @include mq(64em) {
    position: static;
    max-height: none;
    padding: 0;
    transform: none;
    transition: none;
    background: transparent;
  }

  .header-menu-active & {
    transform: translateY(0);
    @include mq(64em) {
      transform: none;
    }
  }

  .header-fixed & {
    @include mq(64em) {
      position: fixed;
      top: 0;
      height: 4rem;
      background: $blue;
      overflow: hidden;
      z-index: auto;
    }
  }
}

.header__menu__wrap {
  .header-fixed & {
    @include mq(64em) {
      position: relative;
      max-width: 80rem;
      margin-right: auto;
      margin-left: auto;
      padding-right: 2rem;
      padding-left: 2rem;
      animation: fadein $transition;
    }
    @include mq(72em) {
      padding-right: 3rem;
      padding-left: 3rem;
    }
    @include mq(80em) {
      padding-right: 4rem;
      padding-left: 4rem;
    }
  }
}

.header__logo-left {
  display: none;

  .header-fixed & {
    @include mq(64em) {
      display: block;
      width: 7.5rem;
      margin-top: 0.3875rem;
      float: left;
      color: $white;
    }
  }
}

.header__menu__group {
  .header-fixed & {
    @include mq(64em) {
      margin-top: 0.84375rem;
      float: right;
    }
  }
}

.header__nav {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom:  0.125rem solid $white-opacity;
  font-family: $sans-serif;
  font-weight: 500;
  list-style: none;
  @include mq(64em) {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.header__nav--util {
  text-transform: uppercase;
  overflow: hidden;
  @include mq(64em) {
    position: absolute;
    top: -0.25rem;
    left: 2rem;
    border-right: 0.0625rem solid $white;
    border-left: 0.0625rem solid $white;
    font-size: 0.875rem;
  }
  @include mq(72em) {
    left: 3rem;
  }
  @include mq(80em) {
    left: 4rem;
  }

  .header-fixed & {
    @include mq(64em) {
      position: static;
      float: left;
    }
  }
}

.header__nav--primary {
  font-size: 2rem;
  @include mq(64em) {
    display: flex;
    position: absolute;
    top: 100%;
    left: 50%;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 80rem;
    height: 4rem;
    padding-right: 2rem;
    padding-left: 2rem;
    transform: translateX(-50%);
    font-size: 1.25rem;
    text-align: center;
  }
  @include mq(72em) {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  @include mq(80em) {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .header-fixed & {
    @include mq(64em) {
      display: none;
    }
  }
}

.header__nav--action {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
  overflow: hidden;
  @include mq(64em) {
    position: absolute;
    top: -0.25rem;
    right: 2rem;
    font-size: 0.875rem;
  }
  @include mq(72em) {
    right: 3rem;
  }
  @include mq(80em) {
    right: 4rem;
  }

  .header-fixed & {
    @include mq(64em) {
      position: static;
      float: left;
    }
  }
}

.header__nav--util,
.header__nav--actiong {
  .header-fixed & {
    @include mq(64em) {
      border: 0;
    }
  }
}

.header__nav__item {
  margin-top: 2rem;
  @include mq(64em) {
    margin-top: 0;
  }

  &:first-child {
    margin-top: 0;
  }

  .header__nav--util & {
    @include mq(64em) {
      float: left;
      border-right: 0.0625rem solid $white;
      border-left: 0.0625rem solid $white;
    }
  }

  .header__nav--primary & {
    @include  mq(64em) {
      display: inline-block;
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      + .header__nav__item {
        margin-left: 2rem;
      }
    }
  }

  .header__nav--action & {
    @include mq(64em) {
      margin-left: 2rem;
      float: left;
    }
  }

  .header__nav--util &,
  .header__nav--action & {
    .header-fixed & {
      @include mq(64em) {
        margin-left: 0.5rem;
        border: 0;
      }
    }
  }
}

.header__nav__item--hidden {
  display: none;
  @include mq(64em) {
    display: block;
  }
}

.header__nav__link {
  color: $white;

  .header__nav--util & {
    @include mq(64em) {
      display: block;
      padding-top: 0.25rem;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      border-top: 0.5rem solid;
      color: $blue;

      &:hover {
        color: $blue-dark;
      }
    }
  }

  .header__nav--primary & {
    @include mq(64em) {
      display: block;
      position: relative;
      background: $blue;
      overflow: hidden;
      -webkit-font-smoothing: antialiased;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0.125rem;
        transform: translateX(-101%);
        transition: transform $transition;
        background: $white;
        content: '';
      }

      &:hover {
        &::before {
          transform: translateX(0);
        }
      }
    }
  }

  .header__nav--primary .header__nav__item--active & {
    @include mq(64em) {
      &::before {
        transform: translateX(0);
      }
    }
  }

  .header__nav--action & {
    @include mq(64em) {
      display: block;
      padding-top: 0.75rem;
      color: $blue;

      &:hover {
        color: $blue-dark;
      }
    }
  }

  .header__nav--util &,
  .header__nav--action & {
    .header-fixed & {
      @include mq(64em) {
        padding: 0.5rem 0.75rem;
        border: 0;
        color: $white;

        &:hover {
          background: $blue-dark;
        }
      }
    }
  }

  .header__nav__item--hidden & {
    .header-fixed & {
      margin-left: 0.75rem;
      padding: 0;
    }
  }
}

.header__nav__icon {
  width: 0.75rem;
  margin-left: 0.25rem;
  padding: 0.25rem 0;
  float: right;

  .header-fixed & {
    width: 2.25rem;
    margin-left: 0;
    padding: 0.5rem;
    border: 0.125rem solid $white;
    border-radius: 50%;
  }
}

.header__nav__text {
  .header-fixed & {
    display: none;
  }
}

.header__search {
  padding-right: 0;
  padding-left: 0;
  @include mq(64em) {
    position: fixed;
    top: 0;
    z-index: auto;
  }

  .header-search-active & {
    transform: translateY(0);
  }
}

.header__search__wrap {
  @include mq(64em) {
    position: relative;
    padding-right: 5.5rem;
    padding-left: 5.5rem;
  }
  @include mq(72em) {
    padding-right: 7.5rem;
    padding-left: 7.5rem;
  }
  @include mq(80em) {
    padding-right: 9.5rem;
    padding-left: 9.5rem;
  }
}

.header__search__close {
  display: none;
  @include mq(64em) {
    display: block;
    position: absolute;
    top: 50%;
    right: 2rem;
    width: 1.5rem;
    transform: translateY(-50%);
    color: $white;
  }
  @include mq(72em) {
    right: 3rem;
  }
  @include mq(80em) {
    right: 4rem;
  }
}

.header__form {
  @extend %clear;
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.0625rem solid $white;
  color: $white;
  font-family: $sans-serif;
  font-size: 1.5rem;
  @include mq(64em) {
    width: calc(50% - 2rem);
    margin-bottom: 0;
    float: left;
  }
  @include mq(72em) {
    width: calc(50% - 3rem);
  }
  @include mq(80em) {
    width: calc(50% - 4rem);
  }

  &:last-child {
    margin-bottom: 0;
    @include mq(64em) {
      float: right;
    }
  }
}

.header__form__input {
  width: calc(100% - 2.25rem);
  float: left;

  &::placeholder {
    color: $white;
  }
}

.header__form__button {
  width: 2.25rem;
  padding: 0.5rem;
  float: right;
  border: 0.125rem solid $white;
  border-radius: 50%;
  color: $white;
}
