.feed {
  margin-bottom: 1rem;
  list-style: none;
}

.feed__item {
  padding-top: 1rem;
  border-top: $divider-gray;

  &:first-child {
    padding-top: 0;
    border-top: 0;
  }
}

.feed__detail {
  @extend %clear;
  @extend %small;
  margin-bottom: 1rem;

  .feed & {
    margin-bottom: 0;
  }
}

.feed__image,
.feed__cycle {
  margin-bottom: 1rem;
}

.feed__image--left,
.feed__image--right {
  @include mq(32em) {
    width: calc(33.333% - 1rem);
  }
}

.feed__image--left {
  @include mq(32em) {
    margin-right: 2rem;
    float: left;
  }
}

.feed__image--right {
  @include mq(32em) {
    margin-left: 2rem;
    float: right;
  }
}

.feed__cycle {
  position: relative;
  height: 0;
  padding-bottom: 66.666%;
  overflow: hidden;
}

.feed__cycle__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 400%;
  height: 100%;

  img {
    height: 100%;
    width: 25%;
    float: left;
  }

  .feed__detail:hover & {
    animation: cycle 3s steps(1) infinite;
  }
}

.feed__title {
  margin-bottom: 0.5rem;
  font-family: $sans-serif;
  font-weight: 500;
}

.feed__title--large {
  font-size: 2rem;
}

.feed__date {
  font-size: 85%;
}
