body {
  font-size: 1.25rem;
}

// placeholders

%h1,
%h2,
%h3,
%h4,
%h5,
%h6 {
  margin-bottom: 1rem;
  font-family: $sans-serif;
  font-weight: 500;
}

%h1 {
  margin-bottom: 2rem;
  font-size: 2.6rem;
  line-height: 1.2;
  @include mq(48em) {
    font-size: 3.25rem;
    line-height: 1.1;
  }
}

%h2 {
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.3;
  @include mq(48em) {
    font-size: 2.75rem;
    line-height: 1.2;
  }
}

%h3 {
  font-size: 1.8rem;
  line-height: 1.4;
  @include mq(48em) {
    font-size: 2.25rem;
    line-height: 1.3;
  }
}

%h4 {
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.4;
  @include mq(48em) {
    font-size: 2rem;
    line-height: 1.3;
  }
}

%h5 {
  font-size: 1.5rem;
  line-height: 1.4;
}

%h6 {
  font-size: 1.25rem;
}

%p,
%ul,
%ol,
%blockquote {
  margin-bottom: 2rem;
}

%ul,
%ol {
  padding-left: 2rem;

  li {
    margin-bottom: 0.75rem;
  }
}

%blockquote {
  position: relative;
  padding-left: 3rem;
  font-size: 2rem;

  &::before {
    position: absolute;
    left: 0;
    color: $tan;
    font-size: 6.5rem;
    line-height: 1;
    content: open-quote;
  }

  p {
    margin-bottom: 1rem;
  }
}

%cite {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: bold;
}

%a {
  @include underline($blue-opacity);
  color: $blue;

  &:hover {
    @include underline;
  }
}

%small {
  font-size: 1.0625rem;

  p {
    margin-bottom: 1rem;
  }
}

// defaults

h1 {
  &:not([class]) {
    @extend %h1;
  }
}

h2 {
  &:not([class]) {
    @extend %h2;
  }
}

h3 {
  &:not([class]) {
    @extend %h3;
  }
}

h4 {
  &:not([class]) {
    @extend %h4;
  }
}

h5 {
  &:not([class]) {
    @extend %h5;
  }
}

h6 {
  &:not([class]) {
    @extend %h6;
  }
}

p {
  &:not([class]) {
    @extend %p;
  }
}

ul {
  &:not([class]) {
    @extend %ul;
  }
}

ol {
  &:not([class]) {
    @extend %ol;
  }
}

blockquote {
  &:not([class]) {
    @extend %blockquote;
  }
}

cite {
  &:not([class]) {
    @extend %cite;
  }
}

a {
  &:not([class]) {
    @extend %a;
  }
}

hr {
  margin-bottom: 2rem;
  border-bottom: $divider-gray;
}

// classes

.p--alt {
  @extend %p;
  font-family: $sans-serif;
  font-weight: 500;
}

.p--large {
  @extend %p;
  font-size: 1.75rem;
  @include mq(48em) {
    font-size: 2.5rem;
  }
}

.ul--plain {
  @extend %ul;
  padding-left: 0;
  list-style: none;
}
