.banner {
  position: relative;
  margin-bottom: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: $blue;
  background-position: center;
  background-size: cover;
  color: $white;
  text-shadow: $shadow;
  @include mq(64em) {
      background-attachment: fixed;
  }


  .wrap {
    position: relative;
  }

  .section + & {
    margin-top: -2rem;
  }

  .section--large + & {
    margin-top: -4rem;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, $black-opacity, transparent);
    content: '';
  }
}

.banner--tan {
  background: $tan-light;
  color: $black;
  text-shadow: none;

  &::before {
    display: none;
  }
}

.banner--arrow {
  padding-bottom: 2.75rem;

  &::after {
    @include fill($svgstore--arrow-mask, $white);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0.75rem;
    background-position: center;
    background-size: 120rem 0.75rem;
    content: '';
  }
}

.banner__split {
  @include mq(64em) {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  :last-child {
    margin-bottom: 0;
  }
}

.banner__image {
  margin-bottom: 2rem;
  @include mq(64em) {
    width: calc(50% - 1rem);
    margin-bottom: 0;
    float: left;
  }
}

.banner__content {
  text-align: center;
  @include mq(64em) {
    width: calc(50% - 1rem);
    margin-left: auto;
    float: right;
  }
}

.banner__headline {
  margin-bottom: 2rem;
  padding-top: 2rem;
  @include mq(32em) {
    padding-top: 4rem;
  }
  @include mq(64em) {
    width: calc(66.666% - 1rem);
    margin-bottom: 0;
    padding-top: 8rem;
    padding-bottom: 8rem;
    float: left;
  }
}

.banner__buttons {
  padding-bottom: 2rem;
  @include mq(32em) {
    padding-bottom: 4rem;
  }
  @include mq(64em) {
    width: calc(33.333% - 1rem);
    margin-left: auto;
    padding-top: 8rem;
    padding-bottom: 8rem;
    float: right;
  }
}

.banner__center {
  margin-top: 2rem;
  margin-bottom: 2rem;
  @include mq(32em) {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  @include mq(64em) {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
}

.banner__bottom {
  margin-top: 4rem;
  @include mq(32em) {
    margin-top: 16rem;
  }
  @include mq(64em) {
    margin-top: 24rem;
  }

  :last-child {
    margin-bottom: 0;
  }
}

.banner__title {
  font-size: 2rem;
  text-align: center;
  @include mq(32em) {
    font-size: 3rem;
  }
  @include mq(64em) {
    font-size: 4rem;
  }
}

.banner__narrow {
  max-width: 32rem;
}
