.subnav {
  width: 100%;
  margin-bottom: 2rem;
  border-top: 0.25rem solid $tan;
  list-style: none;
}

.subnav--nested {
  @extend %hide;
  margin-bottom: 0;
  border-top: 0;

  .subnav__item--active > & {
    @extend %show;
  }
}

.subnav__item {
  display: flex;
  flex-wrap: wrap;
  border-top: 0.0625rem solid $white;

  &:first-child {
    border-top: 0;

    .subnav--nested & {
      border-top: 0.0625rem solid $white;
    }
  }
}

.subnav__toggle {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  width: 2.9375rem;
  padding: 1rem;
  float: right;
  background: $blue;
  color: $white;

  &:hover {
    background: $blue-dark;
  }

  .subnav__item--active > & {
    transform: rotate(180deg);
    background: $blue-dark;
  }
}

.subnav__link {
  @extend %button;
  @extend %button--indent;
  display: block;
  order: -1;
  width: 100%;

  .subnav__toggle + & {
    width: calc(100% - 2.9375rem);
  }

  .subnav__item--active > & {
    background: $blue-dark;
  }
}

.subnav__link--nested {
  @extend %button--gray;
  font-size: 1rem;
}
