.dubourg-animate {
  fill-opacity: 0;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration: 1;
  animation-name: FillIn;
  animation-duration: .5s;
  animation-delay: 2.5s;
}


@keyframes FillIn {
  from { fill-opacity: 0; }
  to { fill-opacity: 1; }
}

.buildings__wrap {
  text-align: center;
  color: $blue;
  margin-top: 1rem;
  p {
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: .1rem solid #000
  }
}
