.feature {
  @extend %clear;
  @include mq(64em) {
    display: flex;
  }
}

.feature__media {
  background-position: center;
  background-size: cover;
  @include mq(64em) {
    width: 50%;
    float: left;
  }

  &::before {
    display: block;
    padding-bottom: 66.666%;
    content: '';
  }
}

.feature__content {
  @include mq(64em) {
    display: flex;
    width: 50%;
    float: right;
    border-top: $divider-gray;
  }

  :last-child {
    margin-bottom: 0;
  }
}

.feature__detail {
  align-self: center;
  width: 100%;
  max-width: 50rem;
  padding: 2rem 1rem;
  @include mq(32em) {
    padding: 2rem;
  }
  @include mq(72em) {
    padding-right: 3rem;
  }
  @include mq(80em) {
    padding-right: 4rem;
    padding-left: 3rem;
  }
}
