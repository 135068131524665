.form {
  padding: 2rem 0;
  margin-bottom: 2rem;
  * + & {
    margin-top: 2rem;
  }
}

.form__label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.25rem;
  .required {

    color: $red;
  }
}

.form__input {
  border: 0.12rem solid $blue;
  width: 100%;
  margin-bottom: 2rem;
  height: 3.5rem;
  &:focus {
    border-color: $blue;
    box-shadow: none;
  }
}

.form__input {
  padding: 0 1rem;
}

.form__select {

  select {
    padding: 0.5rem 10% 0.5rem 0.5rem;
    border: 0.12rem solid $blue;
    @include fill($svgstore--angle-down, $blue);
    border-radius: 0;
    background-position: 90% 50%;
    background-size: 1rem;
  }

}

.form__textarea {
  border: 0.12rem solid $blue;
  width: 100%;
  margin-bottom: 2rem;
  padding: 0.5rem;
  &:focus {
    border-color: $blue;
    box-shadow: none;
  }
}

.control__group {
  margin-bottom: 1rem;
}

.input__select__multiple {
  border: 0.12rem solid $blue;
  option {
    padding: 0.2rem;
  }
}

.input__checkbox {
  margin-right: 0.5rem;
  .checkbox {
      margin-right: 0.5rem;
  }
}

.form-actions {
  button {
    cursor: pointer;
  }
}