$modal-overlay-background-color: #000 !default;
$modal-overlay-background-alpha: 0.75 !default;
$modal-overlay-background: rgba($modal-overlay-background-color, $modal-overlay-background-alpha);
$modal-video-background: #000 !default;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: $modal-overlay-background;
}

.modal__container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.modal__content {

}

.modal__status {
  text-align: center;
}

.modal__item {
  display: block;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.modal__video {
  position: relative;
  padding-bottom: 56.25%;
  background: $modal-video-background;
}

.modal__embed {
  position: absolute;
  width: 100%;
  height: 100%;
}

.modal__controls {

}

.modal__nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.modal__nav--previous {
  left: 0;
}

.modal__nav--next {
  right: 0;
}

.modal__close {
  position: absolute;
  top: 0;
  right: 0;
}


.modal__overlay,
.modal__status,
.modal__controls,
.modal__close {
  opacity: 0;
  transition: opacity 300ms ease;
}
.modal--active .modal__overlay,
.modal--active .modal__controls,
.modal--active .modal__close,
.modal--loading .modal__status,
.modal--loaded .modal__status {
  opacity: 1;
}
.modal__item {
  opacity: 0;
  transform: scale(0.75);
  transition: opacity 300ms ease, transform 300ms ease;
}
.modal--loaded .modal__item {
  opacity: 1;
  transform: scale(1);
}

.modal__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($black-opacity, 0.9);
  animation: fadein $transition;

  .modal--remove & {
    opacity: 0;
    animation: fadeout $transition;
  }
}

.modal__container {
  position: relative;
  width: 100%;
  max-width: 80rem;
  margin: auto;
  padding: 1rem;
  @include mq(32em) {
    padding: 2rem;
  }
  @include mq(72em) {
    padding: 3rem;
  }
  @include mq(80em) {
    padding: 4rem;
  }
}

button.modal__nav {
  color: white;
}

button.modal__nav:hover {
  cursor: pointer;
}

button.modal__nav.modal__nav--previous:before {
    content: '\f053';
    font-family: FontAwesome,Arial,sans-serif;
    color: white;
    font-size: 2rem;
    margin: 0 1rem;
}

button.modal__nav.modal__nav--next:after {
    content: '\f054';
    font-family: FontAwesome,Arial,sans-serif;
    color: white;
    font-size: 2rem;
    margin: 0 1rem;
}