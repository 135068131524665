// sanitize.css options
$root-color: $black;
$root-font-family: $serif;

@import '../../node_modules/sanitize.css/lib/sanitize.scss';

:root {
  word-wrap: break-word;
  overflow-x: hidden;
  font-size: 100%;
  line-height: 1.5;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

svg {
  fill: currentColor;
}

button,
input {
  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}
