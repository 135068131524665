.accordion {
  margin-bottom: 2rem;
  border-bottom: $divider-gray;
}

.accordion__item {
  border-top: $divider-gray;
}

.accordion__toggle {
  @extend %clear;
  @extend %h4;
  display: block;
  display: flex;
  align-items: center;
  padding-top: 1rem;
  color: $black;

  &:hover,
  .accordion__item--active & {
    color: $blue;
  }
}

.accordion__toggle__text {
  width: calc(100% - 3.25rem);
  float: left;
}

.accordion__toggle__icon {
  width: 1.25rem;
  margin-left: auto;
  float: right;
  color: $blue;

  .accordion__item--active & {
    transform: rotate(180deg);
  }
}

.accordion__content {
  @extend %hide;

  .accordion__item--active & {
    @extend %show;
  }
}
