.alert {
  @extend %clear;
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: $red;
  color: $white;
}

.alert__text {
  position: relative;
  padding-right: 2.5rem;
  font-family: $sans-serif;
  font-weight: 500;
  @include mq(32em) {
    padding-right: 3.5rem;
  }
  @include mq(72em) {
    padding-right: 4.5rem;
  }
  @include mq(80em) {
    padding-right: 5.5rem;
  }

  :last-child {
    margin-bottom: 0;
  }
}

.alert__close {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1.5rem;
  transform: translateY(-50%);
  color: $white;
}
