.media {
  @extend %small;
  margin-bottom: 2rem;
  font-family: $sans-serif;
}

.media__image {
  display: block;
  position: relative;
  margin-bottom: 1rem;
}

.media__type {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.5rem;
  background: $black-opacity;
}

.media__icon {
  width: 1.5rem;
  margin-right: 0.25rem;
  padding: 0.25rem;
  float: right;
  color: $white;
}

