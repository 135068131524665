.grid {
  @extend %clear;
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}

.grid--scroll {
  display: block;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  @include mq(32em) {
    margin-right: -2rem;
    margin-left: -2rem;
  }
  @include mq(48em) {
    display: flex;
    margin-right: -1rem;
    margin-left: -1rem;
    white-space: normal;
    overflow: visible;
  }
}

.grid__item {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  float: left;
}

.grid__item--scroll {
  display: inline-block;
  width: 18rem;
  float: none;
  white-space: normal;
  vertical-align: top;
  @include mq(48em) {
    display: block;
    width: 100%;
    float: left;
  }

  &:first-child {
    @include mq(32em) {
      margin-left: 1rem;
    }
    @include mq(48em) {
      margin-left: 0;
    }
  }

  &:last-child {
    @include mq(32em) {
      margin-right: 1rem;
    }
    @include mq(48em) {
      margin-right: 0;
    }
  }
}

.grid__item--flex {
  display: flex;
}

.grid__item--2 {
  @include mq(48em) {
    width: 50%;
  }
}

.grid__item--3 {
  @include mq(48em) {
    width: 50%;
  }
  @include mq(64em) {
    width: 33%;
  }
}


.grid__item--4 {
  @include mq(48em) {
    width: 50%;
  }
  @include mq(64em) {
    width: 25%;
  }
}
