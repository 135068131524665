// transitions
$transition-speed: 320ms;
$transition-easing: ease;
$transition: $transition-speed $transition-easing;

// colors
$blue: #003da5;
$blue-opacity: rgba($blue, 0.4);
$blue-dark: #00286c;
$blue-astro: #003b5c;
$red: #be2e27;
$tan: #9a7851;
$tan-light: #e3d7ad;
$gray: #bcbcbc;
$gray-light: #f0f0f0;
$gray-medium: #667788;
$gray-dark: #444;
$black: #2b2b2b;
$black-opacity: rgba($black, 0.4);
$white: #fff;
$white-opacity: rgba($white, 0.6);

// fonts
$serif: 'Crimson Text', serif;
$sans-serif: 'brandon-grotesque', sans-serif;

// misc
$divider-gray: 0.125rem solid $gray;
$shadow: 0 0 0.5rem $black-opacity;

// animations
@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes cycle {
  0% {
    left: -100%;
  }

  33% {
    left: -200%;
  }

  66% {
    left: -300%;
  }
}
@keyframes fadeup {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes zoomin {
  0% {
    transform: scale(0.75);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes zoomout {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.75);
    opacity: 0;
  }
}
