.dropdown {
  position: relative;
  margin-bottom: 2rem;
}

.dropdown__toggle {
  @extend %button;
  display: block;
  position: relative;
  padding-right: 4.1875rem;

  .dropdown--active & {
    background: $blue-dark;
  }

  .dropdown--subnav & {
    @include mq(64em) {
      display: none;
    }
  }
}

.dropdown__icon {
  position: absolute;
  top: 50%;
  right: 0;
  width: 2.9375rem;
  padding: 1rem;
  transform: translateY(-50%);

  .dropdown--active & {
    transform: translateY(-50%) rotate(180deg);
  }
}

.dropdown__list {
  @extend %hide;
  width: 100%;
  list-style: none;
  z-index: 1;

  .dropdown--active & {
    left: 0;
  }

  .dropdown--subnav & {
    @include mq(64em) {
      @include show;
    }
  }
}

.dropdown__item {
  border-top: 0.0625rem solid $white;
}

.dropdown__link {
  @extend %button;
  @extend %button--indent;
  display: block;
  font-size: 1rem;
}
