.buttons-inline {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

%button,
.button {
  display: inline-block;
  padding: 0.625rem 1.25rem;
  background-color: $blue;
  color: $white;
  font-family: $sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  text-shadow: none;

  &:hover {
    background-color: $blue-dark;
  }

  .buttons-inline & {
    margin-right: 0.5rem;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
  }
}

.button--slide {
  position: relative;
  transform: translate3d(0, 0, 0);
  background-color: transparent;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }

  &::before {
    background: $blue;
    z-index: -2;
  }

  &::after {
    transform: translateX(-100%);
    transition: transform $transition;
    background: $blue-dark;
    z-index: -1;
  }

  &:hover {
    background-color: transparent;

    &::after {
      transform: translateX(0);
    }
  }
}

.button--white {
  padding: 0.5rem 1.125rem;
  border: 0.125rem solid $white;
  background-color: $white;
  color: $blue;

  &:hover {
    border-color: $white;
    background-color: $blue-dark;
    color: $white;
  }
}

%button--gray {
  background: $gray-light;
  color: $blue;

  &:hover {
    background: $gray-light;
    color: $blue-dark;
  }
}

%button--indent {
  padding-right: 1.75rem;
  transition: padding $transition;

  &:hover {
    padding-right: 1.25rem;
    padding-left: 1.75rem;
  }
}

.button--full {
  display: block;
  text-align: center;
}

.button--arrow {
  @include fill($svgstore--angle-right, $blue);
  display: inline;
  padding: 0 1rem 0 0;
  background-color: transparent;
  background-position: right center;
  background-size: 0.75rem 0.75rem;
  color: $blue;

  &:hover {
    @include fill($svgstore--angle-right, $blue-dark);
    background-color: transparent;
    color: $blue-dark;
  }
}

.button__icon {
  width: 1rem;
  margin-top: 0.25rem;
  margin-right: 0.5rem;
  float: left;
}
