.heading {
  margin-bottom: 0.5rem;
  font-family: $sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}

.heading--border {
  padding-top: 0.5rem;
  border-top: $divider-gray;
}

.heading--alt {
  position: relative;
  margin-bottom: 2rem;
  padding-bottom: 1.25rem;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: normal;
  text-align: center;
  text-transform: none;

  &::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 8rem;
    transform: translateX(-50%);
    border-bottom: 0.25rem solid $blue;
    content: '';
  }
}

.heading--alt--left {
  text-align: left;

  &::before {
    left: 0;
    transform: none;
  }
}

.heading--alt--overlay {
  margin-bottom: 1rem;

  &::before {
    border-color: $white;
    box-shadow: $shadow;
  }
}

.heading__icon {
  display: inline-block;
  width: 1.25rem;
  color: $gray;
  vertical-align: text-bottom;
}
