.facebook {
  @extend %small;
  margin-bottom: 2rem;
  font-family: $sans-serif;
  a:not([class]) {
    background-image: none;

    &:hover {
      color: $blue-dark;
    }
  }
}

.facebook__user {
  font-weight: 500;
}

.facebook__handle,
.facebook__time {
  font-size: 0.75rem;
}

.facebook__text {
  margin-top: 1rem;
  margin-bottom: 1rem;

  :last-child {
    margin-bottom: 0;
  }
}

.facebook__meta {
  margin: 1rem 0;
}

.facebook__meta ul {
    display: inline-block;
    width: auto !important;
    padding: 0;
    margin: 0;
}

.facebook__meta li {
    display: inline-block;
    margin: 0 10px 0 0;
    padding: 0;
    background-image: none;
}

.facebook__meta a{
  transition: color 0.2s ease-in-out;
  font-size: .85rem;
  margin: 2rem 1rem 0 0;
  color: $blue;
  &:hover {
    color: $gray-medium;
  }
}
.facebook__meta a:before {
    font-family: "FontAwesome", Arial, sans-serif;
    margin-right: 5px;
    color: $gray;
    transition: color 0.2s ease-in-out;
    &:hover {
      color: $gray-medium;
    }
}
.facebook__meta .like:before {
    content: '\f164';
}

.facebook__meta .comment:before {
    content: '\f075';
}

.facebook__meta .share:before {
    content: '\f064';
}

.facebook__full {
  .facebook__handle, .facebook__time {
      font-size: .75rem;
      line-height: 1.5rem;
      margin: 0 .5rem;
  }

  .facebook__time{
    float: right;
  }

  .facebook__handle, .facebook__user {
    float: left;
  }

  .facebook__text {
    font-size: 1.25rem;
    font-weight: 100;
    clear: both;
    float: left;
  }

}
