.table {
  margin-bottom: 2rem;
  font-size: 1.125rem;

  table {
    width: calc(100% + 0.25rem);
    margin-right: -0.125rem;
    margin-left: -0.125rem;
    border-collapse: separate;
    border-spacing: 0.125rem 0;
    table-layout: fixed;
  }

  tr {
    &:nth-child(odd) {
      td {
        background: $gray-light;
      }
    }
  }

  th,
  td {
    padding: 0.625rem 1.25rem;
    text-align: left;
    vertical-align: top;
  }

  th {
    background: $blue;
    color: $white;
    font-size: 1.25rem;
    font-weight: normal;
  }
}

.table__shadow {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    width: 0.375rem;
    height: 100%;
    background-image: linear-gradient(to right, transparent, $black-opacity);
    content: '';
    pointer-events: none;
  }
}

.table__scroll {
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  table {
    min-width: 64rem;
  }
}
