// clear
%clear {
  @include clear;
}

// hide
%hide {
  @include hide;
}

// show
%show {
  @include show;
}
