.stat {
  @extend %small;
  margin-bottom: 2rem;
  font-family: $sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

.stat__number {
  color: $tan;
  font-family: $serif;
  font-size: 4rem;
}
