.footer {
  margin-top: 4rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-top: 0.75rem solid $gray;
  background: $blue;
  color: $white;
  font-family: $sans-serif;
  text-align: center;
  @include mq(64em) {
    padding-top: 4rem;
    padding-bottom: 4rem;
    text-align: left;
  }

  .banner + &,
  .section--bg + & {
    margin-top: -2rem;
  }

  .section--bg.section--large + & {
    margin-top: -4rem;
  }
}

.footer__main,
.footer__copyright {
  @include mq(64em) {
    width: calc(66.666% - 2rem);
    float: right;
  }
  @include mq(72em) {
    width: calc(66.666% - 3rem);
  }
  @include mq(80em) {
    width: calc(66.666% - 4rem);
  }
}

.footer__tagline {
  margin-bottom: 2rem;
  font-family: $serif;
  font-size: 2.5rem;
  @include mq(64em) {
    margin-bottom: 1rem;
  }
}

.footer__social {
  margin-bottom: 2rem;
  list-style: none;
  @include mq(64em) {
    margin-bottom: 0.75rem;
  }
}

.footer__social__item {
  display: inline-block;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  @include mq(64em) {
    margin-right: 1rem;
    margin-left: 0;
  }
}

.footer__social__link {
  display: block;
  width: 2rem;
  padding: 0.5rem;
  border-radius: 50%;
  background: $white;
  color: $blue;

  &:hover {
    background: $blue-dark;
    color: $white;
  }
}

.footer__nav {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  list-style: none;
  @include mq(64em) {
    margin-bottom: 0.5rem;
  }
}

.footer__nav--util {
  font-size: 1rem;
  @include mq(64em) {
    margin-bottom: 0.75rem;
  }
}

.footer__nav__item {
  margin-bottom: 1rem;
  @include mq(64em) {
    display: inline-block;
    margin-right: 2rem;
    margin-bottom: 0;
  }

  .footer__nav--util & {
    @include mq(64em) {
      margin-right: 1rem;
    }
  }
}

.footer__nav__link {
  color: $white;

  &:hover {
    @include underline;
  }
}

.footer__logo {
  display: block;
  width: 10rem;
  margin-right: auto;
  margin-bottom: 2rem;
  margin-left: auto;
  color: $white;
  @include mq(64em) {
    width: 33.333%;
    margin-top: 1.125rem;
    margin-bottom: 0;
    padding-right: calc(16.666% - 5rem);
    padding-left: calc(16.666% - 5rem);
    float: left;
  }
}

.footer__copyright {
  font-size: 0.75rem;
  text-transform: uppercase;
}
