.card {
  display: block;
  width: 100%;
  margin-bottom: 2rem;
  border-bottom: 0.25rem solid $tan;
  background: $white;
  color: $black;

  &:hover {
    @include mq(32em) {
      background: $blue-dark;
      color: $white;
    }
  }
}

.card__image {
  position: relative;
}

.card__overlay {
  @extend %hide;
  top: 0;
  width: 100%;
  height: 100%;
  background: $white-opacity;
  color: $blue-dark;
  font-family: $sans-serif;
  font-weight: 500;

  .card:hover & {
    @include mq(32em) {
      left: 0;
    }
  }
}

.card__more {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.75rem;
  text-align: center;
}

.card__arrow {
  width: 9rem;
}

.card__detail {
  padding: 1.5rem;
  padding-bottom: 3rem;

  :last-child {
    margin-bottom: 0;
  }
}

.card__title {
  color: $blue;
  font-family: $sans-serif;
  font-size: 1.75rem;
  font-weight: 500;

  .card:hover & {
    @include mq(32em) {
      color: $white;
    }
  }
}
