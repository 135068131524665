.align-left,
.align-right {
  margin-bottom: 2rem;
  @include mq(48em) {
    max-width: calc(50% - 1rem);
  }
}

.align-left {
  @include mq(48em) {
    margin-right: 2rem;
    float: left;
  }
}

.align-right {
  @include mq(48em) {
    margin-left: 2rem;
    float: right;
  }
}

.align-center {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.zoomin {
  opacity: 0;
}

.zoomin--active {
  animation: zoomin $transition-speed * 2 $transition-easing;
}

.hide {
  @extend %hide;
}

.sticky {
  :last-child {
    margin-bottom: 0;
  }
}

.spacing {
  margin-bottom: 2rem;
}

html body {
  .juicer-feed {
    .referral {
      display: none;
    }

    .j-paginate {
      margin-bottom: 0;
    }
  }
}
